<template>
  <div
    class="insight pa-4 d-flex"
  >
    <v-card 
      outlined
      :class="{ 'rounded-r-0': filter.toggle }"
      class="filters align-self-start d-flex flex-column"
      v-click-outside="() => toggleFilter(null)"
    >
      <v-btn
        v-for="(item, key) in filter.items"
        :key="'filter-'+key"
        text
        depressed
        tile
        x-large
        :color="filter.selected==key ? 'primary' : 'grey'"
        :class="{ key }"
        class="btn-filter px-4"
        @click="toggleFilter(key)"
      >
        <v-icon>
          {{ icons[key] }}
        </v-icon>
      </v-btn>
      <v-expand-x-transition>
        <v-card 
          v-show="filter.toggle"
          width="320px"
          outlined
          class="container elevation-2 rounded-tl-0"
        >
          <v-window
            :value="filter.selected"
            vertical
          >
            <v-window-item
              value="location"
            >
              <div class="content">
                <v-card-title class="text-overline px-6 pb-0">{{ filter.items.location.label }}</v-card-title>
                <location-list
                  :city="filter.items.location.city"
                  :geofences="geofences"
                  :selected="filter.items.location.value"
                  :layer="filter.items.location.layer"
                  :layers="filter.items.location.layers"
                  :height="filter.height"
                  :disabled="disabled||filter.items.location.disabled"
                  width="320"
                  class="filter-control location-list flex-shrink-0"
                  @update="(data) => updateFilter('location', data.value)"
                >
                  <template v-slot:header>
                    <v-subheader class="text-overline primary--text mb-1">
                      <v-icon 
                        left 
                        dense
                        color="primary"
                        class="mr-6"
                      >
                        {{ icons.city }}
                      </v-icon>
                      {{ filter.items.location.city.title }}
                    </v-subheader>
                  </template>
                </location-list>
              </div>
            </v-window-item>
            <v-window-item
              value="period"
            >
              <div class="content">
                <v-card-title class="text-overline">{{ filter.items.period.label }}</v-card-title>
              </div>
            </v-window-item>
            <v-window-item
              value="demographics"
            >
              <div class="content">
                <v-card-title class="text-overline">{{ filter.items.demographics.label }}</v-card-title>
              </div>
            </v-window-item>
          </v-window>
        </v-card>
      </v-expand-x-transition>
    </v-card>

    <v-card 
      outlined
      class="container pa-0 ml-4"
    >
      <location-map
        :geofences="mapGeofences"
        :toolbar="false"
        :disabled="disabled||view.map.disabled"
        ref="map"
        class="view map"
        @message="toggleMessage"
      />
      <v-chip
        label
        class="label ma-4"
      >
        Rio de Janeiro | 24/7
      </v-chip>
    </v-card>
  </div>
</template>

<style>

  #insights .filters {
    position: relative;
  }
  #insights .filters .container {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 0 !important;
    z-index: 2;
  }
  #insights .filters .btn-filter {
    min-width: auto !important;
  }
  #insights .filters .btn-filter.label {
    pointer-events: none;
    cursor: default;
  }
  #insights .filters .content {
    width: 320px;
  }
  #insights > .container {
    width: 100%;
  }
  #insights > .container .label {
    position: absolute;
  }
  
</style>

<script>

  // Icons
  import {
    mdiFilter,
    mdiMapMarker,
    mdiCalendarRange,
    mdiAccountGroup,
    mdiCityVariantOutline,
    mdiClose,
    mdiChevronLeft,

  } from '@mdi/js'
  import { sync } from 'vuex-pathify'
  import services from '@/services'


  export default {
    name: 'Insights',

    props: {
      disabled: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        filter: mdiFilter,
        location: mdiMapMarker,
        period: mdiCalendarRange,
        demographics: mdiAccountGroup,
        city: mdiCityVariantOutline,
        back: mdiChevronLeft,
        close: mdiClose,
      },
      filter: {
        toggle: false,
        selected: null,
        items: {
          location: {
            label: 'Geolocalização',
            value: [],
            disabled: false,
            city: {
              id: 3,
              title: 'Rio de Janeiro'
            },
            layer: 'geofences',
            layers: ['geofences'],
          },
          period: {
            label: 'Período',
            value: {},
            disabled: false,
          },
          demographics: {
            label: 'Perfil Demográfico',
            value: {},
            disabled: false,
          },
        },
        height: 400
      },
      view: {
        selected: 'map',
        map: {

          disabled: false,
        }
      }
    }),

    computed: {
      cities: sync('app/views@map.cities'),

      geofences () {
        const city = this.filter.items.location.city;
        const cities = _.clone(!_.isNil(city)&&_.has(this.cities, city.id) ? [this.cities[city.id]] : _.values(this.cities));
        return _.reduce(cities, (geofences, city) => {
          _.each(city.zones, zone => {
            _.each(zone.geofences, g => {
              geofences[g.id] = {
                id: g.id,
                title: g.title,
                url: g.url,
                zone: { id: zone.id, title: zone.title, url: zone.url },
                city: { id: city.id, title: city.title, url: city.url },
                state: _.clone(city.state),
                country: _.clone(city.country)
              };
            });
          });
          return geofences;
        }, {});
      },

      mapGeofences () {
        const geofences = {};
        const data = {};
        return { ...geofences, toggle: true, data }
      }
    },

    watch: {
      cities: {
        immediate: true,
        deep: true,
        handler (cities) {
          const location = this.filter.items.location;
          if (_.isEmpty(location.value)) {
            location.value = _.map(_.clone(this.geofences), 'id');
          }
        }
      }
    },

    methods: {
      ...services,

      toggleFilter (key=null) {
        console.log('toggleFilter', key);
        this.filter.toggle = key!=null;
        const delay = this.filter.toggle ? 0 : 250;
        setTimeout(($, key) => {
          $.filter.selected = key;
        }, delay, this, key);
      },

      updateFilter (filter, data) {
        console.log('updateFilter', filter, data);
        this.filter.items[filter].value = data;
      },

      toggleMessage (message) {
        this.toggleToast(
          true,
          message,
          7000,
          false
        );
      },
    },

    components: {
      LocationList: () => import("@/components/campaigns/LocationList"),
      LocationMap: () => import("@/components/mMap"),
    },

  }
</script>
